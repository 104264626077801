<template>
    <div class="p-grid">
        <div class="p-col-12">
            Page not found
        </div>
    </div>
         
</template>

<script>
export default {
    
}
</script>
<style scoped>
</style>